import { buildNextRouterState } from '../history/buildNextRouterState'
import { isParsedUrlQueryInput, isUrlObject } from '../url'
import { withMd5Id } from '../genMd5Id'
import { ShopRoutes } from '../variables'

export function createSuppliersObject(suppliers: string[]) {
  return suppliers.reduce<Record<string, Record<'id' | 'name', string>>>((acc, key) => {
    acc[key] = { id: key, name: key }
    return acc
  }, {})
}

export function buildSearchUrlWithSupplierFilter(suppliers: string[]) {
  const nextRouterState = buildNextRouterState(ShopRoutes.Search, {
    searchParams: withMd5Id({
      id: '',
      suppliers: createSuppliersObject(suppliers),
    }),
  })
  if (isUrlObject(nextRouterState) && isParsedUrlQueryInput(nextRouterState.query)) {
    return `${nextRouterState.pathname}?state=${nextRouterState.query.state}`
  }
}
