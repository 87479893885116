import React from 'react'
import clsx from 'clsx'
import {
  isPageMatch,
  isStartPage,
  WHITE_BG_PAGES_REGEXP_APP,
  WHITE_BG_PAGES_REGEXP_WEB,
} from '@obeta/utils/lib/styles-helpers'
import { isUiTarget } from '@obeta/utils/lib/isUiTarget'
import styles from './PageBackgroundWrapper.module.scss'
import { useIsChromelessStaticPage } from '@obeta/data/lib/hooks'

type PageBackgroundWrapperProps = {
  path: string
  className?: string
  children: React.ReactNode
}

export const PageBackgroundWrapper = ({
  path,
  children,
  className,
}: PageBackgroundWrapperProps) => {
  const isNativeUITarget = isUiTarget('app')
  const isChromelessStaticPage = useIsChromelessStaticPage()

  const getAppPageBackgroundColor = (path: string) => {
    if (isChromelessStaticPage) {
      return 'transparent'
    }

    if (
      isPageMatch(path, isNativeUITarget ? WHITE_BG_PAGES_REGEXP_APP : WHITE_BG_PAGES_REGEXP_WEB) ||
      isStartPage(path)
    )
      return 'backgroundWhite'

    return 'backgroundGray'
  }

  return <div className={clsx(styles[getAppPageBackgroundColor(path)], className)}>{children}</div>
}
