import { ShippingType } from '../ShoppingCart/ShippingOptions'
import { ShoppingCartPrices } from '../ShoppingCart'
import { Maybe, ResponseMessageAction, ShoppingCartItemResult } from '@obeta/schema'

export interface AddSelectedCartTemplateItemsToCartOptions {
  cartName: string
  itemCount: string
}
export interface AddCartTemplateToCartOptions {
  cartName: string
  cartTemplateName: string
}

export interface AddCartTemplatesToCartTemplateOptions {
  targetTemplateName: string
  originTemplateNames: string[]
}

export interface AddCartTemplateItemsToCartOptions {
  successItemCount: number
  cartName: string
}

export interface AddCartTemplateItemsToCartTemplateOptions {
  successItemCount: number
  targetTemplateName: string
}

export interface AddOrderItemsToCartOptions {
  successItemCount: number
}
export interface AddOrReplaceOfferInCartOptions {
  offerName: string
}

export interface CartApprovalOptions {
  emailAddress: string
  onPrintCart: () => void
}
export interface CartMoveOptions {
  cartEmpty?: boolean
  cartName: string
  includingOffer?: boolean
  itemCount: number
  maximumWidth?: string
}

export interface CartTemplateVisibilityOptions {
  templateName: string
}

export interface DeleteArticleSingleOptions {
  id: string
  cartId: string
  cartEmpty?: boolean
  productTitle: string
}

export interface DeleteArticleMultipleOptions {
  cartEmpty?: boolean
  cartId: string
  id: string
  itemCount: number
}

export interface DeleteTemplateOptions {
  templateName: string
}

export interface DeleteCartTemplateItemSingleOptions {
  cartTemplateEmpty: boolean
  cartTemplateTitle: string
  productTitle: string
}

export interface DeleteCartTemplateItemsOptions {
  cartTemplateEmpty: boolean
  cartTemplateName: string
  itemCount: number
}

export interface FastProductEntryOptions {
  id: string
  name: string
}

export interface FastProductEntryFileUploadSuccessOptions {
  ids: string[]
  count: number
}

export interface EmptyCartOptions {
  cartId: string
  cartName: string
}

export interface OfferExportCreatedOptions {
  fileUrl: string
}

export interface OfferUpdateOptions {
  offerId: string
}

export interface OrderPendingOptions {
  positionTotal: string
  shippingDate: string
  shippingType: ShippingType
  shoppingCartPrices: ShoppingCartPrices
}

export interface OrderSuccessOptions {
  cartId: string
  date: string
  offerId: string
  orderId: string
  pickupNumber: string
  shopPromotionEmail?: string
  shopPromotionId?: string
  shippingType: ShippingType
  storeId: string
  time: string
}

export interface OrderUpdateOptions {
  orderId: string
}

export interface OrderFailureOptions {
  cartId?: string
  reason: OrderFailureReason
  action?: string
}

export interface OrderLimboOptions {
  cartId: string
}

export interface DeleteAddressOptions {
  id: string
}

export interface TemplateAddOptions {
  itemCount: number
  templateName: string
}

export interface CartTemplateCreatedOptions {
  cartTemplateName: string
}

export interface AddToCartMultipleOptions {
  cartTitle: string
  itemCount: number
}

export interface AmountRoundUpOptions {
  id: string
  amountRoundUpNotificationHeading: string
  amountRoundUpNotificationBody: string
}

export interface ElbridgeItemsNotFoundOptions {
  items: {
    errorDetails: {
      supplierId: string
      supplierArticleId: string
      ean: string
    }
  }[]
}

export interface AddToCartFromIdsXmlItemsNotFoundOptions {
  items: Array<ShoppingCartItemResult>
}

export interface SapOfferPdfCreatedOptions {
  fileUrl: string
  offerId: string
}

export interface ChainingOptions {
  notificationIdToRemoveOnRender?: string
  message: string
  title?: string
  duration?: number
}

export interface SapInvoiceCreatedOptions {
  fileUrl: string
  invoiceId?: string
  invoiceIds?: string[]
  missingInvoiceIds?: string[]
}
export interface ConstructionDocumentationCreatedOptions {
  isSmall: boolean
  fileUrl: string
}
export interface ConstructionDocumentationOptions {
  isSmall: boolean
}
export interface DeliverySlipPDFCreatedOptions {
  fileUrl: string
  orderId?: string
  deliverySlipId?: string
}
export interface OrderConfirmationPDFCreatedOptions {
  fileUrl: string
  orderId?: string
}

export interface OrdersExportCreatedOptions {
  fileUrl: string
}

export interface GenericOptions {
  heading: string
  body: string
}

export interface NotificationWithoutDataOptions {
  heading: string
  body: string
  contact?: string
}

export interface VerifyOfferInShoppingCartResultOptions {
  reason: OrderFailureReason
  changedCartItemIds: string[]
}

export interface UpdateUserOptions {
  message: string
  messageActions?: Maybe<Array<ResponseMessageAction>>
  type: string
  action?: string
  userId?: string
}

export interface CreditLimitPreAlertOptions {
  creditAvailable: number
}

export interface CartLockedOptions {
  cartId: string
}

// TODO Rename since it is confusing. Apart from the OrderSubmitFailure all of the other reason describe why a user
// cannot submit a cart rather than some actual issues with an order
export enum OrderFailureReason {
  CartLocked = 'CartLocked',
  CreditLimitExceeded = 'CreditLimitExceeded',
  OrderingBlocked = 'OrderingBlocked',
  OrderSubmitFailure = 'OrderSubmitFailure',
  NoPermissionGranted = 'NoPermissionGranted',
  ShippingBlocked = 'ShippingBlocked',
  OfferNotValid = 'OfferNotValid',
  Custom = 'Custom',
}
