import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'

// Components
import { Categories } from './Categories'
import { MenuButton } from '../MenuButton'
import { SearchInput } from '../searchbar'
import { ToolsList } from './ToolsList'

// Hooks
import { CategoryContext } from '@obeta/data/lib/hooks/useCategoriesList'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { useHistory, useLocation } from '@obeta/data/lib/hooks/useHistoryApi'
import { useMobileScrollHandler } from '@obeta/data/lib/hooks/useMobileScrollHandler'
// REVERT NAV SECTION OFFER
// import { useOfferSearch } from '@obeta/data/lib/hooks/useOfferSearch'
import { useUserDataV2 } from '@obeta/data/lib/hooks/useUserDataV2'

// Models
import { MenuItemColor } from '../dropdown/types'

// Styles
import menuButtonStyles from '../MenuButton/MenuButton.module.scss'
import styles from './NavSection.module.scss'

// Utils
import { isPlatform } from '@obeta/utils/lib/isPlatform'
import { isUiTarget } from '@obeta/utils/lib/isUiTarget'
import { addToDisabledRoutes, removeFromDisabledRoutes } from '@obeta/utils/lib/navSectionHelpers'

// Variables
import { menuItems, SEARCH_INPUT_PLACEHOLDER } from '../../constants'
import { ShopRoutes } from '@obeta/utils/lib/variables'
import { getEventSubscription, NotificationType } from '@obeta/utils/lib/pubSub'
import { useFeatureToggle } from '@obeta/data/lib/hooks/feature-toggles'

export const NavSection = (props) => {
  const { setDehaCatalogLevel0, dehaCatalogLevel0, isLoggedIn } = props
  const { mobile } = useBreakpoints()
  const isMobileDevice = !isPlatform('web') && mobile
  // REVERT NAV SECTION OFFER
  //  const { getTotalOfferCount } = useOfferSearch()
  const { user } = useUserDataV2()
  const { isUserWithSessionBasedCart } = useUserDataV2()
  const isNewProjectsPagesEnabled = useFeatureToggle('UseNewProjectPages')

  // Component state
  const [disabledRoutes, setDisabledRoutes] = useState<ShopRoutes[]>([])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [totalOfferCount, setTotalOfferCount] = useState<number>(1)

  let menuOptions
  if (isLoggedIn) {
    if (isNewProjectsPagesEnabled) {
      menuItems.loggedIn = menuItems.loggedIn.map((item) =>
        item.url === ShopRoutes.Projects ? { ...item, url: ShopRoutes.ProjectOverview } : item
      )
    }
    menuOptions = isPlatform('web') ? menuItems.loggedIn : []
  } else {
    menuOptions = menuItems.notLoggedIn
  }

  const history = useHistory()

  const headerElement = document.getElementById('app-header')

  const show = useMobileScrollHandler({
    targetSectionHeight: 40,
    isEnabled: !mobile,
    options: {
      hoverSection: !isMobileDevice ? headerElement : null,
      showOnScrollUp: true,
    },
  })

  const categoryElementWrapperDiv = useRef<HTMLDivElement>(null)
  const bottomHeaderSectionDiv = useRef<HTMLDivElement>(null)
  const divMobileBottomHeaderSection = useRef<HTMLDivElement>(null)
  const { dispatchRef } = useContext(CategoryContext)
  const isNativeUITarget = isUiTarget('app')

  /**
   * Get total offer count by offer input.
   */
  // REVERT NAV SECTION OFFER
  /*  const getTotalOfferCountFn = useCallback(async () => {
    const offerCount = await getTotalOfferCount({
      filter: [],
      limit: '0',
      offset: '0',
      orderBy: 'offerId',
      orderDir: 'ASC',
      searchTerm: '',
    })
    setTotalOfferCount(offerCount)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])*/

  const sendCategoryRefs = useCallback(() => {
    dispatchRef({
      type: 'SET_CATEGORIES_REFS',
      payload: {
        categoryElementWrapperDivoffsetLeft: categoryElementWrapperDiv.current?.offsetLeft,
        bottomHeaderSectionDivoffsetTop: bottomHeaderSectionDiv.current?.offsetTop,
        bottomHeaderSectionDivoffsetHeight: bottomHeaderSectionDiv.current?.offsetHeight,
        divMobileBottomHeaderSectionoffsetTop: divMobileBottomHeaderSection.current?.offsetTop,
        divMobileBottomHeaderSectionoffsetHeight:
          divMobileBottomHeaderSection.current?.offsetHeight,
      },
    })
  }, [dispatchRef])
  const location = useLocation()

  // Get total offer count on mount (disable button)
  // REVERT NAV SECTION OFFER
  /*  useEffect(() => {
    if (isLoggedIn) {
      getTotalOfferCountFn()
    }
  }, [getTotalOfferCountFn, isLoggedIn])*/

  // Disable routes
  useEffect(() => {
    if (totalOfferCount < 1) {
      const updatedDisabledRoutes = addToDisabledRoutes(ShopRoutes.OfferList, disabledRoutes)
      if (updatedDisabledRoutes) {
        setDisabledRoutes(updatedDisabledRoutes)
      }
    } else {
      disabledRoutes.includes(ShopRoutes.OfferList) &&
        setDisabledRoutes(removeFromDisabledRoutes(ShopRoutes.OfferList, disabledRoutes))
    }
  }, [disabledRoutes, totalOfferCount])

  // Subscribe to update and delete events to update total offer count
  useEffect(() => {
    const sub = getEventSubscription().subscribe((event) => {
      if (
        event.notificationType === NotificationType.OfferAdd ||
        event.notificationType === NotificationType.OfferUpdate ||
        event.notificationType === NotificationType.OfferDelete
      ) {
        // Offer data maybe not reflected in real time
        setTimeout(() => {
          // REVERT NAV SECTION OFFER
          /*getTotalOfferCountFn()*/
        }, 1000)
      }
    })
    return () => {
      sub.unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    sendCategoryRefs()
  }, [sendCategoryRefs, mobile])

  useEffect(() => {
    window.addEventListener('resize', sendCategoryRefs)
    return () => {
      window.removeEventListener('resize', sendCategoryRefs)
    }
  }, [sendCategoryRefs])

  if (!show) {
    return <div className={styles.hiddenSection} />
  }

  if (mobile) {
    return (
      <div
        className={clsx(styles.bottomHeaderSection, {
          [styles.bottomHeaderSectionMobile]: isMobileDevice,
        })}
        ref={divMobileBottomHeaderSection}
      >
        <div>
          <Categories
            setDehaCatalogLevel0={setDehaCatalogLevel0}
            dehaCatalogLevel0={dehaCatalogLevel0}
          />
        </div>
        <div className={styles.searchbar}>
          <SearchInput
            placeholder={SEARCH_INPUT_PLACEHOLDER}
            showBarCodeScanner={!isPlatform('web')}
          />
        </div>
        {isLoggedIn && (
          <div className={styles.moreIcon}>
            <ToolsList isMobile />
          </div>
        )}
      </div>
    )
  }

  return (
    <div className={styles.bottomHeaderSection} ref={bottomHeaderSectionDiv}>
      <div className={styles.bottomHeaderContent}>
        <div ref={categoryElementWrapperDiv}>
          <Categories
            setDehaCatalogLevel0={setDehaCatalogLevel0}
            dehaCatalogLevel0={dehaCatalogLevel0}
          />
        </div>
        <div className={styles.rightSideBottomSection}>
          {!isNativeUITarget && isLoggedIn && menuOptions.length > 0 && (
            <>
              {!isUserWithSessionBasedCart && user?.permissions?.Projects_canManage && (
                <MenuButton
                  active={
                    isNewProjectsPagesEnabled
                      ? location.pathname === ShopRoutes.ProjectOverview
                      : location.pathname === ShopRoutes.Projects
                  }
                  title={menuItems.loggedIn[0].title}
                  onClick={() =>
                    menuItems.loggedIn[0].url ? history.push(menuItems.loggedIn[0].url) : ''
                  }
                />
              )}
              {user?.permissions?.Orders_canRead && (
                <div
                  className={clsx(menuButtonStyles.menuButton, {
                    [menuButtonStyles.menuButtonActive]:
                      location.pathname === ShopRoutes.OrdersList,
                  })}
                >
                  <MenuButton
                    active={location.pathname === ShopRoutes.OrdersList}
                    key={menuItems.loggedIn[1].title}
                    title={menuItems.loggedIn[1].title}
                    onClick={() =>
                      // Once the new orders page is used as default and the legacy one cannot be accessed anymore,
                      // retrieve value via menuItems.loggedIn[1] or menuItems.loggedIn[2] respectively
                      history.push(ShopRoutes.OrdersList)
                    }
                  />
                </div>
              )}
              {user?.permissions?.Offers_canRead && (
                <MenuButton
                  active={location.pathname === ShopRoutes.OfferList}
                  disabled={disabledRoutes.includes(menuItems.loggedIn[2].url as ShopRoutes)}
                  title={menuItems.loggedIn[2].title}
                  onClick={() =>
                    menuItems.loggedIn[2].url ? history.push(menuItems.loggedIn[2].url) : ''
                  }
                />
              )}
              <MenuButton
                active={location.pathname === menuItems.loggedIn[3].url}
                title={menuItems.loggedIn[3].title}
                onClick={() =>
                  menuItems.loggedIn[3].url ? history.push(menuItems.loggedIn[3].url) : ''
                }
              />
            </>
          )}

          {isLoggedIn && (
            <div className={clsx(menuButtonStyles.menuButton, menuButtonStyles.menuButtonEnabled)}>
              <ToolsList color={MenuItemColor.White} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
