import { EventType, getEventSubscription, NotificationEvent, NotificationType } from './pubSub'
import {
  MessageType,
  ResponseMessage,
  ResponseMessageWithModelId,
  ResponseMessageWithActions,
} from '@obeta/schema'

type ResponseMessageToNotificationOptions = {
  responseMessage: ResponseMessage | ResponseMessageWithActions | ResponseMessageWithModelId
  notificationType?: NotificationType
  options?: NotificationEvent['options']
  id?: string
}

/**
 * Creates a notification from a {@link ResponseMessage} object
 * @param {ResponseMessage} responseMessage - received as part of a response
 */
export function responseMessageToNotification(
  responseMessage: ResponseMessage | ResponseMessageWithModelId | ResponseMessageWithActions,
  options?: { duration?: number; notificationIdToRemoveOnRender?: string }
) {
  getEventSubscription().next({
    type: EventType.Data,
    notificationType: messageTypeToNotificationType(responseMessage.type),
    id: 'response-message-notification',
    options: {
      message: responseMessage.message,
      duration: options?.duration,
      notificationIdToRemoveOnRender: options?.notificationIdToRemoveOnRender,
    },
  })
}

/**
 * Creates a notification from a {@link ResponseMessage} object
 * @param {ResponseMessage} responseMessage - received as part of a response
 * @param {string} id - the id can be used, i.e. to remove the notication later on
 */
export function responseMessageToNotificationAdvanced({
  responseMessage,
  id,
  notificationType,
  options,
}: ResponseMessageToNotificationOptions) {
  getEventSubscription().next({
    type: EventType.Data,
    notificationType: notificationType ?? messageTypeToNotificationType(responseMessage.type),
    id: id ?? 'response-message-notification',
    options: options ?? {
      message: responseMessage.message,
      messageActions: 'actions' in responseMessage ? responseMessage.actions : undefined,
    },
  })
}

export function responseMessageToUpdateUserNotification(
  responseMessage: ResponseMessageWithActions | ResponseMessage | ResponseMessageWithModelId,
  action?: string,
  userId?: string
): void {
  const id = `updated-user-${new Date().getTime()}`
  responseMessageToNotificationAdvanced({
    responseMessage,
    id,
    notificationType: NotificationType.UpdateUser,
    options: {
      message: responseMessage.message,
      messageActions: 'actions' in responseMessage ? responseMessage.actions : undefined,
      type: responseMessage.type,
      action: action ?? undefined,
      userId: userId ?? undefined,
    },
  })
}

function messageTypeToNotificationType(
  messageType: MessageType
): NotificationType.Confirm | NotificationType.Error {
  switch (messageType) {
    case 'Success':
      return NotificationType.Confirm
    case 'Error':
      return NotificationType.Error
  }
}
