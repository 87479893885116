import { FC, ReactNode, useEffect, useLayoutEffect, useState } from 'react'
import { useHeaderDimensions } from '@obeta/data/lib/hooks/useHeaderDimensions'
import { useRouter } from 'next/router'
import styles from './MainWrapper.module.scss'
import { useIsChromelessStaticPage } from '@obeta/data/lib/hooks'

interface IMainWrapperProps {
  children: ReactNode
}

export const MainWrapper: FC<IMainWrapperProps> = ({ children }) => {
  const router = useRouter()
  const [marginTop, setMarginTop] = useState(0)

  const [isOnlineShopRoute, setIsOnlineShopRoute] = useState<boolean>(false)
  const {
    dimensions: { height: headerHeight },
  } = useHeaderDimensions()

  const isChromelessStaticPage = useIsChromelessStaticPage()

  useEffect(() => {
    setIsOnlineShopRoute(!!router.asPath.match(/\/onlineshop\//))
  }, [router])

  useLayoutEffect(() => {
    if (window.scrollY === 0) {
      /*
       * we'll only take note of the collapsed header height the moment we leave the upper border
       * and we'll only reset it when returning to the upper border. by this we avoid excessive stylechanges that led to flickering in Firefox
       */
      setMarginTop(headerHeight)
    }
  }, [headerHeight])

  return (
    <main
      style={{ marginTop: `${marginTop}px` }}
      className={!isOnlineShopRoute && !isChromelessStaticPage ? styles.marginIframe : ''}
    >
      {children}
    </main>
  )
}
