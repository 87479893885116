import { FetchResult, gql, useMutation } from '@apollo/client'
import {
  SubmitUserRegistrationInput,
  SubmitUserRegistrationMutation,
  CreateUserRegistrationUploadUrlInput,
  CreateUserRegistrationUploadUrlMutation,
  DeleteUserRegistrationFileUploadInput,
  DeleteUserRegistrationFileUploadMutation,
} from '@obeta/schema'

export const CREATE_UPLOAD_URL = gql`
  mutation createUserRegistrationUploadUrl($input: CreateUserRegistrationUploadUrlInput!) {
    createUserRegistrationUploadUrl(input: $input) {
      params
      s3Key
      uploadUrl
    }
  }
`
export const DELETE_FILE_UPLOAD = gql`
  mutation deleteUserRegistrationFileUpload($input: DeleteUserRegistrationFileUploadInput!) {
    deleteUserRegistrationFileUpload(input: $input) {
      success
      errorCode
      errorMessage
    }
  }
`

export const SUBMIT_USER_REGISTRATION = gql`
  mutation submitUserRegistration($input: SubmitUserRegistrationInput!) {
    submitUserRegistration(input: $input) {
      errorCode
      errorMessage
      files
      success
    }
  }
`

export const useUserRegistration = () => {
  const [submitUserRegistrationMutation] = useMutation<SubmitUserRegistrationMutation>(
    SUBMIT_USER_REGISTRATION,
    {
      onCompleted: (data) => {
        return data.submitUserRegistration
      },
      onError: (error) => {
        return error
      },
    }
  )

  const [createUploadUrlMutation] = useMutation<CreateUserRegistrationUploadUrlMutation>(
    CREATE_UPLOAD_URL,
    {
      onCompleted: (data) => {
        return data.createUserRegistrationUploadUrl
      },
      onError: (error) => {
        return error
      },
    }
  )

  const [deleteFileUploadMutation] = useMutation<DeleteUserRegistrationFileUploadMutation>(
    DELETE_FILE_UPLOAD,
    {
      onCompleted: (data) => {
        return data.deleteUserRegistrationFileUpload
      },
      onError: (error) => {
        return error
      },
    }
  )

  const submitUserRegistration = async (
    input: SubmitUserRegistrationInput
  ): Promise<FetchResult<SubmitUserRegistrationMutation>> => {
    return await submitUserRegistrationMutation({ variables: { input } })
  }

  const createUploadUrl = async (
    input: CreateUserRegistrationUploadUrlInput
  ): Promise<FetchResult<CreateUserRegistrationUploadUrlMutation>> => {
    return await createUploadUrlMutation({ variables: { input } })
  }

  const deleteFileUpload = async (
    input: DeleteUserRegistrationFileUploadInput
  ): Promise<FetchResult<DeleteUserRegistrationFileUploadMutation>> => {
    return await deleteFileUploadMutation({ variables: { input } })
  }

  return {
    deleteFileUpload,
    submitUserRegistration,
    createUploadUrl,
  }
}
