import {
  CreateFastProductEntryUploadUrlResponse,
  CreateUserRegistrationUploadUrlOutput,
  CreateUserUploadUrlResponse,
} from '@obeta/schema'

type UploadFileResponse =
  | CreateFastProductEntryUploadUrlResponse
  | CreateUserUploadUrlResponse
  | CreateUserRegistrationUploadUrlOutput

export const uploadFile = async (
  file: File,
  createUploadUrlResponse: UploadFileResponse
): Promise<Response | undefined> => {
  const formData = new FormData()
  formData.append('key', createUploadUrlResponse.params.key)
  formData.append('acl', createUploadUrlResponse.params.acl)
  formData.append('content-type', createUploadUrlResponse.params['content-type'])
  formData.append('policy', createUploadUrlResponse.params.policy)
  formData.append('success_action_status', createUploadUrlResponse.params.success_action_status)
  formData.append('x-amz-algorithm', createUploadUrlResponse.params['x-amz-algorithm'])
  formData.append('x-amz-credential', createUploadUrlResponse.params['x-amz-credential'])
  formData.append('x-amz-date', createUploadUrlResponse.params['x-amz-date'])
  formData.append('x-amz-signature', createUploadUrlResponse.params['x-amz-signature'])
  formData.append('file', file)

  return await fetch(createUploadUrlResponse.uploadUrl, {
    method: 'POST',
    body: formData,
  })
}
