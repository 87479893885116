import React, { RefObject } from 'react'
import { SignUpTopSection } from './components/SignUpTopSection'
import clsx from 'clsx'
import { ReactComponent as ObetaTrippleBrandingLogo } from 'assets/icon/designsystem/triple-branding-obeta-logo.svg'
import styles from './SignUpHeader.module.scss'
import { useHeaderDimensions } from '@obeta/data/lib/hooks/useHeaderDimensions'

/**
 *
 * @param props.trippleBrandingLogo - logo with name of all three brandings. rendered in desktop mode.
 * @returns
 */
const Logo: React.FC<{ trippleBrandingLogo: JSX.Element | null }> = (props) => {
  const { trippleBrandingLogo } = props

  return trippleBrandingLogo
}

interface IHeaderProps {
  className?: string
  logo: JSX.Element | null
}

const SignUpHeader = React.forwardRef<HTMLDivElement, IHeaderProps>(function Header(props, ref) {
  const { logo, className } = props
  useHeaderDimensions(ref as RefObject<HTMLElement>)

  return (
    <div ref={ref} className={clsx(styles.headerContainer, className)} id="app-header">
      <SignUpTopSection
        logo={logo}
        email={'neukunden@obeta.de'}
        phoneNumber={'+49 30 720 94 - 550'}
      />
    </div>
  )
})

export const SignUpShopHeader = React.forwardRef<HTMLDivElement>((_, ref) => {
  return (
    <SignUpHeader
      ref={ref}
      className={styles.shopHeader}
      logo={<Logo trippleBrandingLogo={<ObetaTrippleBrandingLogo />} />}
    />
  )
})
