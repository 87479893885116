import { AddressV2 } from '../ShoppingCart/AddressV2'
import { Tokens } from '../BusinessLayer/AppActions'
import { CustomerCard } from 'graphql-codegen'

export type Nullable<T> = T | null // why it's here?

export interface UserAddressV2 {
  addressId: string
  address: AddressV2
  addressType: UserAddressType
  customName: string
  updatedAt: string
  isDefault?: boolean
}
export enum UserAddressType {
  MainAddress = 'MainAddress', // (Hauptadresse)
  CustomerAddress = 'CustomerAddress', // (Kundenadresse)
  SpecialTour = 'SpecialTour', // (Sondertour)
  DeliveryAddress = 'DeliveryAddress', // (Lieferaddresse)
}

export class UserAddressCreateOutput {
  createUserAddress: UserAddressOutput
}
export class UserAddressUpdateOutput {
  updateUserAddress: UserAddressOutput
}
export class UserAddressDeleteOutput {
  deleteUserAddress: UserAddressOutput
}

export class UserAddressOutput {
  handleResult: UserAddressHandleResult
}
export class UserAddressHandleResult {
  success: boolean
  errorCode: string
  errorMessage: string
  addressId?: string
}

/**
 * Permissions are stacking
 * If you have permission for PurchasePrice, then you have
 * permission to see all other prices.
 * If you don't have permission to see PurchasePrice, but
 * have permission to see CatalogPrice, then you also have
 * permission to see ListPrice.
 *
 */
export enum PricePermissions {
  NoPrice = 'NoPrice',
  PurchasePrice = 'PurchasePrice',
  CatalogPrice = 'CatalogPrice',
  ListPrice = 'ListPrice',
}

export enum CartWritePermission {
  Notify = 'Notify',
  Submit = 'Submit',
  None = 'None',
}

export interface UserV2Permissions {
  Global_canReadPrices: PricePermissions
  Address_canWrite: boolean
  Cart_canWriteAddress: boolean
  Cart_canWriteCommission: boolean
  Cart_canWriteAdditionalText: boolean
  Cart_requiredCommission: boolean
  Cart_allowedSubmitAction: CartWritePermission
  Cart_canChangePayment: boolean
  Cart_maxCartValue: number | null
  Offers_canRead: boolean
  Orders_canRead: boolean
  Global_canReadAccountInfo: boolean
  Global_canUseInterfaces: boolean
  Global_canSeeUserManagement: boolean
  Global_canUseCustomDataSheet: boolean
  Global_canChangeDataSheetPrice: boolean
  Global_showCreditWarning: boolean
  Projects_canManage: boolean
}

export enum UserType {
  main = 'main',
  sub = 'sub',
}

export interface UserV2 {
  customerCard: CustomerCard
  notificationEmail: string
  customerNumber: string
  isTecselectUser: boolean
  tecselectLoginUrl: string
  algoliaUserToken: string
  permissions: UserV2Permissions
  settings?: {
    defaultStoreId: string
    defaultCartId: string
    name: string
    profilePictureS3Path: string
  }
  type: UserType
  hasFreeShipping: boolean
  isActive: boolean
}

export interface getUserV2Result {
  getUser: UserV2
}

export interface UserV2Data {
  user: Nullable<UserV2>
  companyId?: string
  /**
   * Represents the `customerId`. Use `fullUserId` for the complete `userId`
   */
  userId?: string
  /**
   * The complete user id, which concatenates `companyId` and `customerId`.
   */
  fullUserId?: string
  permissions?: UserV2Permissions
  isLoggedIn: boolean
  tokens: Nullable<Tokens>
  allTokensExist: boolean
  metaDataReady: boolean
  isTokensFetched: boolean
  algoliaUserToken?: string
  isUserWithSessionBasedCart?: boolean
  singleCartMode: boolean
}

export interface CustomerMetaData {
  isFetching: boolean
  mustRefetch: boolean
  isLoggedIn: boolean
  lastUpdated: number | null
  companyId: string
  userId?: string
}
