import React from 'react'
import styles from './SignUpTopSection.module.scss'
import { useMobileScrollHandler } from '@obeta/data/lib/hooks/useMobileScrollHandler'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { isPlatform } from '@obeta/utils/lib/isPlatform'
import { useRemToPx } from '@obeta/data/lib/hooks/useRemToPixel'
import { Typography } from '@mui/material'
import { useRedirect } from '@obeta/data/lib/hooks/useRedirect'
import { ShopLink } from '../../../link/ShopLink'

type SignUpTopSectionProps = {
  logo: JSX.Element | null
  email: string
  phoneNumber: string
}

export const SignUpTopSection: React.FC<SignUpTopSectionProps> = (props) => {
  const { logo, email, phoneNumber } = props
  const { mobile } = useBreakpoints()
  const isWeb = isPlatform('web')
  const { redirectToStartPage } = useRedirect()

  const mobileSectionHeight = useRemToPx(parseFloat(styles.mobileFullHeight))

  const headerElement = document.getElementById('app-header')

  const show = useMobileScrollHandler({
    targetSectionHeight: mobileSectionHeight,
    isEnabled: mobile,
    options: {
      hoverSection: isWeb ? headerElement : null,
      showOnScrollUp: true,
    },
  })

  if (!show) {
    return null
  }

  return (
    <div className={styles.topHeaderSection}>
      <div className={styles.topHeaderContent}>
        <ShopLink
          href={'/'}
          onClick={(event: React.MouseEvent<HTMLElement>) => redirectToStartPage(event)}
        >
          <div className={styles.logo}>{logo}</div>
        </ShopLink>
        {!mobile && (
          <>
            <Typography className={styles.letter} variant="bodyBold">
              {'M '}
              <Typography variant={'bodyBold'} color={'secondary'}>
                {`${email}`}
              </Typography>
            </Typography>
            <Typography className={styles.letter} variant="bodyBold">
              {'T '}
              <Typography variant={'bodyBold'}>{`${phoneNumber}`}</Typography>
            </Typography>
          </>
        )}
      </div>
    </div>
  )
}
