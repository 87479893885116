import { gql, useApolloClient } from '@apollo/client'
import {
  CreateUserMutation,
  CreateUserMutationVariables,
  DeleteUserByIdMutation,
  DeleteUserByIdMutationVariables,
  MessageType,
  TriggerEmailTokenRefreshMutation,
  TriggerEmailTokenRefreshMutationVariables,
  TriggerPasswordResetMailMutation,
  TriggerPasswordResetMailMutationVariables,
  UpdateUserMutation,
  UpdateUserMutationVariables,
} from '@obeta/schema'
import { UserPermissions } from '@obeta/models/lib/schema-models/userV2-subusers'
import { useCallback, useState } from 'react'
import { responseMessageToUpdateUserNotification } from '@obeta/utils/lib/responseMessageToNotification'
import { removeTypename } from '@obeta/utils/lib/removeTypename'

export interface updateUserPayload {
  subUserId: string
  isActive: boolean
  permissions: UserPermissions
  email: string
  name: string
  notificationEmail: string
  orderConfirmationEmail: string
}

export interface createUserPayload {
  permissions: UserPermissions
  email: string
  name: string
  notificationEmail?: string
}

export interface triggerEmailTokenRefreshPayload {
  subUserId: string
  userId: string
}

export const gqlCreateUser = gql`
  mutation createUser($input: CreateUserInput!) {
    createUser(input: $input) {
      type
      message
    }
  }
`

export const gqlUpdateUser = gql`
  mutation updateUser($input: UserInputV2!) {
    updateUserV2(input: $input) {
      type
      message
    }
  }
`

export const gqlUserDelete = gql`
  mutation deleteUserById($input: String!) {
    deleteUserById(input: $input) {
      type
      message
    }
  }
`

export const gqlTriggerEmailTokenRefresh = gql`
  mutation triggerEmailTokenRefresh($input: RefreshTokenInput!) {
    triggerEmailTokenRefresh(input: $input) {
      type
      message
    }
  }
`

export const gqlTriggerPasswordResetMail = gql`
  mutation triggerPasswordResetMail($input: String!) {
    triggerPasswordResetMail(input: $input) {
      type
      message
    }
  }
`

export const useUserActiveState = (isActive: boolean) => {
  const [userActive, setUserActive] = useState<boolean>(isActive)
  const client = useApolloClient()

  const dispatchUserUpdate = useCallback(
    async (payload: updateUserPayload) => {
      if (payload.notificationEmail === null || payload.notificationEmail === undefined) {
        payload.notificationEmail = ''
      }
      const response = await client.mutate<UpdateUserMutation, UpdateUserMutationVariables>({
        mutation: gqlUpdateUser,
        variables: {
          input: removeTypename(payload),
        },
      })

      if (response.data?.updateUserV2[0]) {
        if (response.data?.updateUserV2[0].type === 'Success') {
          setUserActive(response.data?.updateUserV2[0].message.indexOf('deaktiviert') === -1)
        }
        responseMessageToUpdateUserNotification(response.data?.updateUserV2[0])
      }
    },
    [client]
  )

  return { dispatchUserUpdate, userActive, setUserActive }
}

export const useUserPermissionUpdate = () => {
  const client = useApolloClient()

  return useCallback(
    async (payload: updateUserPayload) => {
      const permissions = { ...payload.permissions, __typename: undefined }
      if (payload.notificationEmail === null || payload.notificationEmail === undefined) {
        payload.notificationEmail = ''
      }

      const response = await client.mutate<UpdateUserMutation, UpdateUserMutationVariables>({
        mutation: gqlUpdateUser,
        variables: {
          input: removeTypename({ ...payload, permissions: permissions }),
        },
      })

      if (response.data?.updateUserV2[0]) {
        responseMessageToUpdateUserNotification(response.data?.updateUserV2[0])
      }
    },
    [client]
  )
}

export const useUserCreate = () => {
  const client = useApolloClient()

  return useCallback(
    async (payload: createUserPayload) => {
      if (payload.notificationEmail === null || payload.notificationEmail === undefined) {
        payload.notificationEmail = ''
      }
      const response = await client.mutate<CreateUserMutation, CreateUserMutationVariables>({
        mutation: gqlCreateUser,
        variables: {
          input: removeTypename(payload),
        },
      })

      if (response.data?.createUser) {
        responseMessageToUpdateUserNotification(response.data?.createUser)
      }
    },
    [client]
  )
}

export const useUserUpdate = () => {
  const client = useApolloClient()

  return useCallback(
    async (payload: updateUserPayload) => {
      if (payload.notificationEmail === null || payload.notificationEmail === undefined) {
        payload.notificationEmail = ''
      }

      const response = await client.mutate<UpdateUserMutation, UpdateUserMutationVariables>({
        mutation: gqlUpdateUser,
        variables: {
          input: removeTypename(payload),
        },
      })

      if (response.data?.updateUserV2 && response.data?.updateUserV2.length > 0) {
        let allMessages = ''
        let allType: MessageType = 'Success'
        response.data?.updateUserV2.forEach((responseMessage, index) => {
          allMessages += ' ' + responseMessage.message
          if (responseMessage.type !== 'Success') {
            allType = 'Error'
          }
        })
        responseMessageToUpdateUserNotification({ message: allMessages, type: allType })
      }
    },
    [client]
  )
}

export const useUserDelete = () => {
  const client = useApolloClient()

  return useCallback(
    async (userId: string) => {
      const response = await client.mutate<DeleteUserByIdMutation, DeleteUserByIdMutationVariables>(
        {
          mutation: gqlUserDelete,
          variables: {
            input: userId,
          },
        }
      )

      if (response.data?.deleteUserById) {
        responseMessageToUpdateUserNotification(response.data?.deleteUserById)
      }
    },
    [client]
  )
}

export const useTriggerEmailTokenRefresh = () => {
  const client = useApolloClient()

  return useCallback(
    async (payload: triggerEmailTokenRefreshPayload) => {
      const response = await client.mutate<
        TriggerEmailTokenRefreshMutation,
        TriggerEmailTokenRefreshMutationVariables
      >({
        mutation: gqlTriggerEmailTokenRefresh,
        variables: {
          input: payload,
        },
      })

      if (response.data?.triggerEmailTokenRefresh) {
        responseMessageToUpdateUserNotification(response.data?.triggerEmailTokenRefresh)
      }
    },
    [client]
  )
}

export const useTriggerPasswordReset = () => {
  const client = useApolloClient()

  return useCallback(
    async (payload: string) => {
      const response = await client.mutate<
        TriggerPasswordResetMailMutation,
        TriggerPasswordResetMailMutationVariables
      >({
        mutation: gqlTriggerPasswordResetMail,
        variables: {
          input: payload,
        },
      })

      if (response.data?.triggerPasswordResetMail) {
        responseMessageToUpdateUserNotification(response.data?.triggerPasswordResetMail)
      }
    },
    [client]
  )
}
