import { useTranslation } from 'react-i18next'
import { createContext, useCallback, useContext, useMemo, useState } from 'react'
import { EventType, getEventSubscription, NotificationType } from '@obeta/utils/lib/pubSub'
import { uploadFile } from '@obeta/utils/lib/upload-utils/uploadFile'
import { useUserRegistration } from '../hooks/useUserRegistration'
import { FileRejection } from 'react-dropzone'
const initialEmptyFunction = () => {
  //
}

interface UploadedUserFile {
  name: string
  s3Key: string
  size: number
}
interface UserRegistrationContextValue {
  captchaToken: string
  setCaptchaToken: (captchaToken: string) => void
  captchaTokenError: string
  setCaptchaTokenError: (captchaTokenError: string) => void
  termsAccepted: boolean
  setTermsAccepted: (termsAccepted: boolean) => void
  privacyPolicyAccepted: boolean
  setPrivacyPolicyAccepted: (privacyPolicyAccepted: boolean) => void
  fileError?: string
  uploadedFiles: UploadedUserFile[]
  setUploadedFiles: (files: UploadedUserFile[]) => void
  isFileUploading: boolean
  isLoading: boolean
  setIsLoading: (isLoading: boolean) => void
  handleDropAccepted: (acceptedFiles: File[]) => Promise<void>
  handleDropRejected: (fileRejections: FileRejection[]) => void
  handleDeleteFile: (s3Key: string) => Promise<void>
  MAX_FILE_COUNT: number
  MAX_FILE_SIZE_IN_BYTES: number
}
const UserRegistrationContext = createContext<UserRegistrationContextValue>({
  captchaToken: '',
  setCaptchaToken: initialEmptyFunction,
  captchaTokenError: '',
  setCaptchaTokenError: initialEmptyFunction,
  termsAccepted: false,
  setTermsAccepted: initialEmptyFunction,
  privacyPolicyAccepted: false,
  setPrivacyPolicyAccepted: initialEmptyFunction,
  fileError: '',
  uploadedFiles: [],
  setUploadedFiles: initialEmptyFunction,
  isFileUploading: false,
  isLoading: false,
  setIsLoading: initialEmptyFunction,
  handleDropAccepted: () => {
    return Promise.resolve()
  },
  handleDropRejected: initialEmptyFunction,
  handleDeleteFile: () => Promise.resolve(),
  MAX_FILE_COUNT: 5,
  MAX_FILE_SIZE_IN_BYTES: 38797312,
})

export const UserRegistrationProvider = ({ children }) => {
  const { t } = useTranslation()

  const [captchaToken, setCaptchaToken] = useState('')
  const [captchaTokenError, setCaptchaTokenError] = useState('')
  const [termsAccepted, setTermsAccepted] = useState(false)
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false)
  const [fileError, setFileError] = useState<string | undefined>(undefined)
  const [uploadedFiles, setUploadedFiles] = useState<UploadedUserFile[]>([])
  const [isFileUploading, setIsFileUploading] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const { createUploadUrl, deleteFileUpload } = useUserRegistration()

  const MAX_FILE_COUNT = 5
  const MAX_FILE_SIZE_IN_BYTES = 38797312 // = 37 MB
  const MAX_FILE_SIZE_IN_MEGA_BYTES = MAX_FILE_SIZE_IN_BYTES / (1024 * 1024)

  const handleDeleteFile = useCallback(
    async (s3Key: string) => {
      await deleteFileUpload({ s3Key: s3Key })
      setUploadedFiles((files) => files.filter((file) => file.s3Key !== s3Key))
      setFileError(undefined)
    },
    [deleteFileUpload, setUploadedFiles, setFileError]
  )

  const handleFileUploadError = useCallback(() => {
    getEventSubscription().next({
      options: { id: 'user-registration-file-upload-error' },
      type: EventType.Toast,
      notificationType: NotificationType.FastProductEntryFileUploadErrorCommon,
      id: 'user-registration-file-upload-error',
    })
    setIsFileUploading(false)
  }, [])

  const handleDropRejected = useCallback(
    (files) => {
      files.forEach((file) => {
        file.errors &&
          file.errors.forEach((error) => {
            // Note that these errors only apply per drop and/or per file respectively
            // Hence, we set the file error to the first error encountered and then return early
            switch (error.code) {
              case 'too-many-files':
                setFileError(
                  t('SIGNUP.WARNING.MAX_FILE_COUNT_EXCEEDED', {
                    maxFiles: MAX_FILE_COUNT,
                  })
                )
                return
              case 'file-invalid-type':
                setFileError(t('SIGNUP.WARNING.INVALID_FILE_TYPE'))
                return
              case 'file-too-large':
                setFileError(
                  t('SIGNUP.WARNING.FILE_SIZE_EXCEEDED', {
                    maxFileSizeInMB: MAX_FILE_SIZE_IN_MEGA_BYTES,
                  })
                )
                return
            }
          })
      })
      return
    },
    [MAX_FILE_COUNT, MAX_FILE_SIZE_IN_MEGA_BYTES, setFileError, t]
  )

  const isTotalFileSizeValid = useCallback(
    (acceptedFiles: File[]) => {
      const uploadedFilesSize = uploadedFiles.reduce((total, file) => {
        return total + (file.size || 0)
      }, 0)

      const acceptedFilesSize = acceptedFiles.reduce((total, file) => total + file.size, 0)

      // Check if total size exceeds limit
      const totalSize = uploadedFilesSize + acceptedFilesSize
      if (totalSize > MAX_FILE_SIZE_IN_BYTES) {
        setFileError(
          t('SIGNUP.WARNING.FILE_SIZE_EXCEEDED', {
            maxFileSizeInMB: MAX_FILE_SIZE_IN_MEGA_BYTES,
          })
        )
        return false
      }

      return true
    },
    [uploadedFiles, t, MAX_FILE_SIZE_IN_MEGA_BYTES]
  )

  const isLatestFileDropValid = useCallback(
    (acceptedFiles: File[]) => {
      // Note: File type has been evaluated already by useDropzone and would have caused files to be rejected

      // First, check if total file count exceeds limit
      if (uploadedFiles.length + acceptedFiles.length > MAX_FILE_COUNT) {
        setFileError(
          t('SIGNUP.WARNING.MAX_FILE_COUNT_EXCEEDED', {
            maxFiles: MAX_FILE_COUNT,
          })
        )
        return false
      }
      // Second, check if cumulative file size exceeds limit
      return isTotalFileSizeValid(acceptedFiles)
    },
    [uploadedFiles, MAX_FILE_COUNT, setFileError, isTotalFileSizeValid, t]
  )

  const uploadFileToAWS = useCallback(
    async (file: File) => {
      try {
        const { data } = await createUploadUrl({ contentType: file.type, fileName: file.name })
        if (!data) {
          console.error('Failed to create upload URL')
          setFileError(t('SIGNUP.WARNING.GENERIC'))
          return
        }

        const response = await uploadFile(file, data.createUserRegistrationUploadUrl)
        if (response?.status !== 201) {
          console.error('File upload failed')
          setFileError(t('SIGNUP.WARNING.GENERIC'))
          return
        }

        setUploadedFiles((files) => [
          ...files,
          {
            name: file.name,
            s3Key: data.createUserRegistrationUploadUrl.s3Key,
            size: file.size,
          },
        ])
      } catch (error) {
        setFileError(t('SIGNUP.WARNING.GENERIC'))
        console.error(error)
      }
    },
    [createUploadUrl, setFileError, setUploadedFiles, t]
  )

  const handleDropAccepted = useCallback(
    async (acceptedFiles: File[]) => {
      setFileError(undefined)
      if (acceptedFiles.length === 0) return

      setIsFileUploading(true)

      // Add checks before attempting to upload the files to AWS
      if (!isLatestFileDropValid(acceptedFiles)) {
        setIsFileUploading(false)
        return
      }
      try {
        await Promise.all(acceptedFiles.map((file) => uploadFileToAWS(file)))
      } catch (error) {
        handleFileUploadError()
      } finally {
        setIsFileUploading(false)
      }
    },
    [
      isLatestFileDropValid,
      uploadFileToAWS,
      handleFileUploadError,
      setIsFileUploading,
      setFileError,
    ]
  )

  const value: UserRegistrationContextValue = useMemo(
    () => ({
      captchaToken,
      setCaptchaToken,
      captchaTokenError,
      setCaptchaTokenError,
      termsAccepted,
      setTermsAccepted,
      privacyPolicyAccepted,
      setPrivacyPolicyAccepted,
      fileError,
      uploadedFiles,
      setUploadedFiles,
      isFileUploading,
      isLoading,
      setIsLoading,
      handleDropAccepted,
      handleDropRejected,
      handleDeleteFile,
      MAX_FILE_COUNT,
      MAX_FILE_SIZE_IN_BYTES,
    }),
    [
      captchaToken,
      setCaptchaToken,
      captchaTokenError,
      setCaptchaTokenError,
      termsAccepted,
      setTermsAccepted,
      privacyPolicyAccepted,
      setPrivacyPolicyAccepted,
      fileError,
      uploadedFiles,
      setUploadedFiles,
      isFileUploading,
      isLoading,
      setIsLoading,
      handleDropAccepted,
      handleDropRejected,
      handleDeleteFile,
      MAX_FILE_COUNT,
      MAX_FILE_SIZE_IN_BYTES,
    ]
  )
  return (
    <UserRegistrationContext.Provider value={value}>{children}</UserRegistrationContext.Provider>
  )
}

export const useUserRegistrationContext = () => {
  return useContext(UserRegistrationContext)
}
