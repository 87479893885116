import { ShopDomains } from '@obeta/utils/lib/domains'
import { gql, useApolloClient } from '@apollo/client'
import { useAppDomain } from './useAppDomain'
import { isPlatform } from '@obeta/utils/lib/isPlatform'
import { useEffect, useRef, useState } from 'react'
import { datadogRum } from '@datadog/browser-rum'
import { isUiTarget } from '@obeta/utils/lib/isUiTarget'

export const INFO_HEADER_QUERY = gql`
  query GetHeaderMessages(
    $filters: HeaderMessageFiltersInput
    $pagination: PaginationArg = {}
    $sort: [String] = []
    $publicationState: PublicationState = LIVE
  ) {
    headerMessages(
      filters: $filters
      pagination: $pagination
      sort: $sort
      publicationState: $publicationState
    ) {
      data {
        id
        attributes {
          type
          displayState
          message
          url
          openInNewTab
        }
      }
    }
  }
`

type InfoType = 'INFO' | 'HINT'

type DisplayState = 'loggedOut' | 'loggedIn' | 'always'

export interface IHeaderInfo {
  type: InfoType
  message: string
  url?: string
  openInNewTab?: boolean
  displayState: DisplayState
}

interface IStrapiHeaderInfoResponse {
  data: {
    headerMessages: {
      data: { attributes: IHeaderInfo }[]
    }
  }
}

const domainMapping = {
  [ShopDomains.Obeta]: 'showForObeta',
  [ShopDomains.Eldis]: 'showForEldis',
  [ShopDomains.Haeusler]: 'showForHaeusler',
}

const getVariables = (domain: ShopDomains) => {
  const key = domainMapping[domain]

  const showForFilter =
    isPlatform('web') && !isUiTarget('app') ? { [key]: { eq: true } } : { showInApp: { eq: true } }

  const currentDate = new Date()

  const filter = {
    and: [
      {
        or: [{ startTime: { lte: currentDate } }, { startTime: { eq: null } }],
      },
      {
        or: [{ endTime: { gte: currentDate } }, { endTime: { eq: null } }],
      },
      showForFilter,
    ],
  }

  return { filters: filter }
}

const transformResponse = (response: IStrapiHeaderInfoResponse, isLoggedIn?: boolean) => {
  const data = response.data.headerMessages.data

  const result = data.length ? data[data.length - 1].attributes : undefined

  if (
    (result?.displayState === 'loggedIn' && !isLoggedIn) ||
    (result?.displayState === 'loggedOut' && isLoggedIn) ||
    !result
  ) {
    return undefined
  }

  return result
}

export const useInfoHeader = ({
  skip,
  isLoggedIn,
}: {
  skip: boolean
  isLoggedIn: boolean
}): IHeaderInfo | undefined => {
  const isMounted = useRef(true)
  const client = useApolloClient()
  const [data, setData] = useState<IHeaderInfo | undefined>(undefined)

  const domain = useAppDomain()

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    const getInfo = async () => {
      if (!isMounted.current || skip) return

      try {
        const response = await client.query({
          query: INFO_HEADER_QUERY,
          variables: getVariables(domain),
        })

        setData(transformResponse(response, isLoggedIn))
      } catch (err: unknown) {
        datadogRum.addError(err)
      }
    }

    getInfo()
  }, [domain, skip, isLoggedIn, client])

  return data
}
