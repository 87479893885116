import { useMemo } from 'react'
import { useLocation } from './useHistoryApi'
import { useQuery } from './useQuery'

/**
 * we are supporting rendering obeta static content chromeless (meaning without header and footer)
 * this allows an easy integration of strapi configured content (e.g. dataprotection, or news content)
 * in external applications such as webflow pages
 */

const CHROMLESS_ACCEPTABLE = [
  /^\/content(.*)/,
  /^\/privacy-protection$/,
  /^\/terms-and-conditions$/,
  /^\/about-us$/,
]

export const useIsChromelessStaticPage = () => {
  const queryParams = useQuery()
  const loc = useLocation()
  const hasChromelessParam = !!queryParams.get('chromeless')
  const canAcceptChromeless = useMemo(() => {
    for (const page of CHROMLESS_ACCEPTABLE) {
      if (loc.pathname.match(page)) return true
    }
  }, [loc.pathname])

  return hasChromelessParam && canAcceptChromeless
}
